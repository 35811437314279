<template>
  <div class="image-editor-container" :class="{ 'hide-block-manager': hideBlockManager }">
    <!-- GrapesJS를 렌더링할 컨테이너 -->
    <div ref="gjsContainer" class="editor-canvas"></div>
  </div>
</template>

<script>
import GrapesJS from 'grapesjs'
import grapesjsPresetWebpage from 'grapesjs-preset-webpage'
import grapesjsTuiImageEditor from 'grapesjs-tui-image-editor'

export default {
  name: 'ImageEditor',
  props: {
    // 부모로부터 편집할 HTML을 전달받을 때 쓰는 prop
    html: {
      type: String,
      default: '<p>Hello GrapesJS!</p>'
    },
    hideBlockManager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      isRtePopupActive: false,  // 추가: RTE 팝업 활성화 상태 추적
      lastSelectedTextComponent: null,
      saveTimeout: null,
      autoSaveInterval: null,
      rteCheckInterval: null,
      lastSavedHtml: '',
      isRteActive: false
    }
  },
  mounted() {
    // 1) GrapesJS 초기화 - 최소 구성으로 설정
    this.editor = GrapesJS.init({
      container: this.$refs.gjsContainer,
      fromElement: false,
      height: '100%',
      width: 'auto',
      storageManager: {
        type: 'none',   // localStorage 사용 안함
        autosave: false // 자동 저장 비활성화
      },
      plugins: [
        grapesjsPresetWebpage,
        grapesjsTuiImageEditor
      ],
      pluginsOpts: {
        [grapesjsPresetWebpage]: {
          blocks: ['text', 'image'], // 필요한 블록만 활성화
        },
        [grapesjsTuiImageEditor]: {
          script: [
            'https://uicdn.toast.com/tui-color-picker/latest/tui-color-picker.min.js',
            'https://uicdn.toast.com/tui-image-editor/latest/tui-image-editor.min.js'
          ],
          config: {
            includeUI: {
              menuBarPosition: 'bottom',
              theme: {
                // require로 assets 폴더 내의 파일들 가져오기
                'menu.normalIcon.path': require('@/assets/images/icon/icon-d.svg'),
                'menu.activeIcon.path': require('@/assets/images/icon/icon-b.svg'),
                'menu.disabledIcon.path': require('@/assets/images/icon/icon-a.svg'),
                'menu.hoverIcon.path': require('@/assets/images/icon/icon-c.svg')
              }
            },
            cssMaxWidth: 700,
            cssMaxHeight: 700,
            usageStatistics: false,
          },
          labelImageEditor: '이미지 편집',
          labelApply: '적용',
          labelClose: '닫기',
        },
      },
      // 기본 패널 유지 (load 이벤트에서 필요한 것만 남기기)
      // canvas: {
      //   styles: [],
      //   scripts: []
      // },
      showDevices: false,  // 디바이스 선택 기능 비활성화
      // 간소화된 스타일 매니저
      styleManager: {
        sectors: [
          {
            name: '텍스트',
            open: true,
            properties: [
              { name: '폰트', property: 'font-family' },
              { name: '크기', property: 'font-size' },
              { name: '색상', property: 'color', type: 'color' },
              { name: '두께', property: 'font-weight' },
              { name: '정렬', property: 'text-align' }
            ]
          },
          {
            name: '배경',
            properties: [
              { name: '배경색', property: 'background-color', type: 'color' }
            ]
          },
          {
            name: '여백',
            properties: [
              { name: '안쪽여백', property: 'padding' },
              { name: '바깥여백', property: 'margin' }
            ]
          }
        ]
      }
    });

    // 2) 에디터에 HTML 로드
    this.editor.setComponents(this.html);

    // 3) 커스텀 컴포넌트 등록
    this.addResizableTextType();

    // 4) 기본 텍스트 타입을 resizable로 설정
    this.setupDefaultResizableText();

    // 4) 에디터 로드 후 필요한 설정만 남기기
    // 4) 에디터 로드 후 필요한 설정만 남기기
    this.editor.on('load', () => {
      const panels = this.editor.Panels;

      // 1. devices-c 패널 제거 (이미 구현되어 있는 코드)
      panels.removePanel('devices-c');

      // 2. commands 패널 관리 - 일단 유지 (비어있음)

      // 3. options 패널에서 불필요한 버튼 제거
      const optionsButtons = [
        'sw-visibility',      // 컴포넌트 외곽선 표시 버튼 (유지 가능)
        'preview',            // 미리보기 버튼
        'fullscreen',         // 전체화면 버튼
        'export-template',    // 템플릿 내보내기 버튼
        'undo',               // 실행 취소 버튼
        'redo',               // 다시 실행 버튼
        'gjs-open-import-webpage', // 웹페이지 가져오기 버튼
        'canvas-clear'        // 캔버스 비우기 버튼
      ];

      // open-blocks만 유지하고 나머지 버튼 제거
      optionsButtons.forEach(btnId => {
        panels.removeButton('options', btnId);
      });

      // 4. views 패널 관리 (open-blocks 버튼만 남기고 제거)
      const viewsButtons = [
        'open-sm',       // 스타일 매니저 버튼
        'open-tm',       // 트레이트 매니저 버튼
        'open-layers'    // 레이어 매니저 버튼
      ];

      // open-blocks 버튼을 제외한 나머지 버튼 제거
      viewsButtons.forEach(btnId => {
        panels.removeButton('views', btnId);
      });

      // 블록 매니저에 사이즈 표 추가
      const blockManager = this.editor.BlockManager;

      blockManager.add('size-table', {
        id: 'size-table',
        label: 'Table',
        category: 'Basic',
        attributes: {
          'data-id': 'size-table',
          'class': 'size-table-block'
        },
        content: `
          <table style="width: 100%; border-collapse: collapse; margin: 15px 0;">
            <thead>
              <tr>
                <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: center;"><span>사이즈</span></th>
                <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: center;"><span>가슴둘레(cm)</span></th>
                <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: center;"><span>허리둘레(cm)</span></th>
                <th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; text-align: center;"><span>엉덩이둘레(cm)</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>S</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>85-90</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>70-75</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>85-90</span></td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>M</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>90-95</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>75-80</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>90-95</span></td>
              </tr>
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>L</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>95-100</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>80-85</span></td>
                <td style="border: 1px solid #ddd; padding: 8px; text-align: center;"><span>95-100</span></td>
              </tr>
            </tbody>
          </table>
        `,
        // 표 아이콘 SVG 사용
        media: `<svg viewBox="0 0 24 24" width="100" height="100"><path fill="#4b9fff" d="M4,3H20A2,2 0 0,1 22,5V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V5A2,2 0 0,1 4,3M4,7V10H8V7H4M10,7V10H14V7H10M20,7H16V10H20V7M4,12V15H8V12H4M4,20H8V17H4V20M10,12V15H14V12H10M10,20H14V17H10V20M20,12H16V15H20V12M20,20H16V17H20V20Z"></path></svg>`
      });

      // + 버튼 생성 및 추가
      const addBlockButton = document.createElement('div');
      addBlockButton.className = 'add-block-button';
      addBlockButton.innerHTML = '<span>+</span>';
      addBlockButton.title = '블록 추가';

      // 에디터 프레임의 부모 요소에 버튼 추가
      const editorContainer = this.editor.Canvas.getFrameEl().parentNode;
      editorContainer.appendChild(addBlockButton);

      // 블록 패널 참조
      const blockPanel = document.querySelector('.gjs-pn-views-container');

      // 토글 기능 구현
      let isPanelVisible = false;
      addBlockButton.addEventListener('click', (e) => {
        e.stopPropagation(); // 이벤트 전파 중지

        isPanelVisible = !isPanelVisible;

        if (blockPanel) {
          if (isPanelVisible) {
            blockPanel.classList.add('panel-open');
            addBlockButton.classList.add('active');
            // 블록 매니저 렌더링 확인
            this.editor.BlockManager.render();
          } else {
            blockPanel.classList.remove('panel-open');
            addBlockButton.classList.remove('active');
          }
        }
      });

      // 패널 외부 클릭 시 닫기
      document.addEventListener('click', (e) => {
        if (isPanelVisible &&
          blockPanel &&
          !blockPanel.contains(e.target) &&
          !addBlockButton.contains(e.target)) {
          isPanelVisible = false;
          blockPanel.classList.remove('panel-open');
          addBlockButton.classList.remove('active');
        }
      });

      // 블록 패널 준비
      this.editor.BlockManager.render();

      // 6. 레이어, 스타일, 트레이트 매니저 비활성화
      const layerManager = this.editor.LayerManager;
      const styleManager = this.editor.StyleManager;
      const traitManager = this.editor.TraitManager;

      // 수정된 코드
      if (layerManager) {
        const config = layerManager.getConfig();
        if (config) config.appendTo = null;
      }

      if (styleManager) {
        const config = styleManager.getConfig();
        if (config) config.appendTo = null;
      }

      if (traitManager) {
        const config = traitManager.getConfig();
        if (config) config.appendTo = null;
      }

      // 이미 존재하는 모든 컴포넌트에 대해 기본 툴바 아이콘 제거
      const comps = this.editor.DomComponents.getWrapper().find('*');
      comps.forEach(cmp => {
        this.removeDefaultToolbarIcons(cmp);

        // 텍스트 컴포넌트를 resizable-text로 변환
        if (cmp.is('text') && !cmp.is('resizable-text')) {
          this.convertToResizableText(cmp);
        }
      });

      // 새로 추가되는 컴포넌트도 동일 처리
      this.editor.on('component:add', comps => {
        if (comps && (comps.is('text') || comps.is('resizable-text'))) {
          this.enableDragging(comps);
        }
        if (Array.isArray(comps)) {
          comps.forEach(cmp => {
            this.removeDefaultToolbarIcons(cmp);

            // 텍스트 컴포넌트를 resizable-text로 변환
            if (cmp.is('text') && !cmp.is('resizable-text')) {
              this.convertToResizableText(cmp);
            }
          });
        } else {
          this.removeDefaultToolbarIcons(comps);

          // 텍스트 컴포넌트를 resizable-text로 변환
          if (comps.is('text') && !comps.is('resizable-text')) {
            this.convertToResizableText(comps);
          }
        }
        this.handleComponentChange
      });

      this.editor.on('component:selected', comp => {
        if (!comp) return;

        try {
          // console.log('component selected:', comp.get('type'), comp.get('editable'));

          // 다른 컴포넌트 선택 시 열려있는 RTE 팝업 정리
          if (this.isRtePopupActive) {
            this.closeAllRtePopups();
          }
        } catch (e) {
          console.warn('Error in component:selected handler', e);
        }
      });
      this.editor.on('component:update', this.handleComponentChange);

      this.editor.on('component:remove', this.handleComponentChange);
      // 스타일 변경 이벤트
      this.editor.on('component:styleUpdate', this.handleComponentChange);
      this.editor.on('component:update:style', this.handleComponentChange);

      // 속성 변경 이벤트
      this.editor.on('component:update:attributes', this.handleComponentChange);

      // 내용 변경 이벤트
      this.editor.on('component:update:content', this.handleComponentChange);

      // 이미지 편집 후 이벤트
      this.editor.on('tui:image:edited', this.handleComponentChange);

      this.editor.on('rte:enable', view => {
        // console.log('RTE enabled on:', view.model.get('type'), view.el);
        this.isRteActive = true;
        this.lastSelectedTextComponent = view.model;
      });
      // RTE 비활성화 이벤트 감지
      this.editor.on('rte:disable', view => {
        // console.log('RTE disabled on:', view.model.get('type'));
        this.isRteActive = false;
        this.handleComponentChange();
        // 모든 팝업 정리
        this.closeAllRtePopups();

        try {
          const model = view.model;
          if (model && (model.is('text') || model.is('resizable-text'))) {
            // RTE 비활성화 시 draggable 속성 복원 (지연 적용)
            setTimeout(() => {
              // 안전하게 모델이 아직 유효한지 확인
              if (model && model.get) {
                model.set('draggable', true);

                // 컴포넌트 재선택
                try {
                  this.editor.select(model);
                } catch (e) {
                  console.warn('Error reselecting component:', e);
                }
              }
            }, 100);
          }
        } catch (e) {
          console.warn('Error in rte:disable handler:', e);
        }
      });

      // mounted 메서드 내 load 이벤트 핸들러에 있는 dblclick 이벤트 리스너 수정
      this.editor.Canvas.getDocument().addEventListener('dblclick', e => {
        const target = e.target;
        console.log('Double click target:', target);

        // 이미 RTE가 활성화된 상태라면 중복 처리 방지
        if (this.isRteActive) {
          return;
        }

        const closestComp = this.editor.getSelected();

        // 선택된 컴포넌트가 resizable-text인 경우
        if (closestComp && (closestComp.is('resizable-text') || closestComp.is('text'))) {
          // 편집 모드 전환
          closestComp.set('editable', true);
          closestComp.set('draggable', false);

          // 내장 명령어 사용
          this.editor.runCommand('core:component-enter');
        }
      });

      // 텍스트 드래그 문제 해결을 위한 이벤트 리스너 추가
      const canvasDoc = this.editor.Canvas.getDocument();

      // 마우스 이동 및 업 이벤트 추적
      canvasDoc.addEventListener('mouseup', () => {
        // console.log(e)
        // RTE가 활성화된 상태에서만 동작
        if (this.isRteActive && this.lastSelectedTextComponent) {
          // 작은 지연을 두고 컴포넌트 재선택
          setTimeout(() => {
            try {
              // 마지막으로 선택된 텍스트 컴포넌트가 아직 유효한지 확인
              if (this.lastSelectedTextComponent && this.lastSelectedTextComponent.get) {
                this.editor.select(this.lastSelectedTextComponent);
              }
            } catch (err) {
              console.warn('Error reselecting component after mouseup:', err);
            }
          }, 50);
        }
      });

      // 몸체 영역 밖으로 마우스가 나갔을 때 처리
      canvasDoc.addEventListener('mouseleave', () => {
        // console.log(e)
        // RTE가 활성화된 상태에서만 동작
        if (this.isRteActive && this.lastSelectedTextComponent) {
          // 작은 지연을 두고 컴포넌트 재선택
          setTimeout(() => {
            try {
              if (this.lastSelectedTextComponent && this.lastSelectedTextComponent.get) {
                this.editor.select(this.lastSelectedTextComponent);
              }
            } catch (err) {
              console.warn('Error reselecting component after mouseleave:', err);
            }
          }, 50);
        }
      });

      // component:moved 이벤트 핸들러 수정 (라인 171 근처)
      this.editor.on('component:moved', comp => {
        console.log('component moved:', comp.get('type'), comp.get('editable'));

        if (comp.is('resizable-text') || comp.is('text')) {
          // 이동 후 속성 재설정 (delay를 줘서 상태가 안정된 후 적용)
          setTimeout(() => {
            comp.set('editable', true);

            // 다시 선택하여 UI 업데이트
            this.editor.select(comp);

            // 명시적으로 draggable 속성 재설정 (이동은 가능하게)
            comp.set('draggable', true);
          }, 50);
        }
        this.handleComponentChange
      });

      // mounted 메서드 내 load 이벤트 핸들러에 커스텀 명령어 수정
      // 텍스트 편집을 위한 커스텀 명령어 (수정)
      this.editor.Commands.add('custom-edit-text', {
        run(editor) {
          const selected = editor.getSelected();
          if (selected && (selected.is('text') || selected.is('resizable-text'))) {
            // 편집을 위한 설정
            selected.set('editable', true);
            selected.set('draggable', false);

            // 내장 명령어 실행
            editor.runCommand('core:component-enter');
          }
        },
        // 중요: 편집 모드 종료 시 draggable 속성 복원
        stop(editor) {
          const selected = editor.getSelected();
          if (selected && (selected.is('text') || selected.is('resizable-text'))) {
            selected.set('draggable', true);
          }
        }
      });

      const commands = this.editor.Commands;
      const dragCmdOrig = commands.get('core:component-drag');

      if (dragCmdOrig) {
        // 기존 드래그 명령어 확장
        commands.add('core:component-drag', {
          ...dragCmdOrig,
          run(editor, sender, options = {}) {
            const target = options.target || editor.getSelected();

            // 타겟이 있고 text 또는 resizable-text인 경우
            if (target && (target.is('text') || target.is('resizable-text'))) {
              // draggable 속성 확인 및 설정
              target.set('draggable', true);
            }

            // 원래 명령어 실행
            dragCmdOrig.run(editor, sender, options);
          }
        });
      }

      this.setupRichTextEditor();



      // 편집 모드 설정
      this.editor.setDevice('Desktop');

      // mounted 메서드 또는 적절한 위치에 추가
      this.$nextTick(() => {
        const panelContainer = document.querySelector('.gjs-pn-views-container');
        if (panelContainer) {
          panelContainer.addEventListener('click', function (e) {
            if (e.target === this.querySelector(':before') || e.offsetX < 0) {
              this.classList.toggle('panel-open');
            }
          });
        }
      });

      this.editor.on('run:core:component-enter', () => {
        // 컴포넌트 내용 편집 시작 후 타이머 설정
        this.setupRteChangeDetection();
        this.handleComponentChange
      });

      // this.setupAutoSave();
    });

    // 6) 변경 내용 자동 저장 (옵션)
    this.editor.on('change:changesCount', () => {
      this.handleComponentChange
      // 변경 감지 시 처리할 로직
      // console.log('내용이 변경되었습니다');
    });
  },
  methods: {// 컴포넌트 변경 처리
    handleComponentChange() {
      if (this.editor) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(() => {
          const html = this.editor.getHtml();
          this.lastSavedHtml = html;
          this.$emit('save', html);
        }, 500); // 500ms 디바운스
      }
    },

    // RTE 변경 감지 설정
    setupRteChangeDetection() {
      // RTE 활성화 시 주기적으로 변경 사항 확인
      clearInterval(this.rteCheckInterval);
      this.rteCheckInterval = setInterval(() => {
        if (this.isRteActive) {
          this.handleComponentChange();
        } else {
          clearInterval(this.rteCheckInterval);
        }
      }, 1000); // 1초 간격으로 확인
    },

    // 자동 저장 타이머 설정
    setupAutoSave() {
      clearInterval(this.autoSaveInterval);
      // 3초마다 변경 사항 확인 및 저장
      this.autoSaveInterval = setInterval(() => {
        if (this.editor) {
          const currentHtml = this.editor.getHtml();
          if (this.lastSavedHtml !== currentHtml) {
            this.lastSavedHtml = currentHtml;
            this.$emit('save', currentHtml);
          }
        }
      }, 3000);
    },
    // 모든 RTE 팝업을 정리하는 메소드 추가
    closeAllRtePopups() {
      // 모든 RTE 팝업 컨테이너 찾기
      const popups = document.querySelectorAll('.rte-popup-container');

      // 모든 팝업 제거
      popups.forEach(popup => popup.remove());

      // 팝업 상태 초기화
      this.isRtePopupActive = false;
    },
    /**
 * RTE 팝업의 위치를 설정하는 메소드 (iframe 내부 좌표 사용)
 * @param {HTMLElement} popupEl - 위치를 설정할 팝업 요소
 * @param {HTMLElement} rteEl - RTE 요소 (텍스트 요소)
 */
    setRtePopupPosition(popupEl, rteEl) {
      if (!popupEl || !rteEl) return;

      // iframe 요소 가져오기
      const frameEl = this.editor.Canvas.getFrameEl();
      const frameRect = frameEl.getBoundingClientRect();

      // iframe 내부 문서 및 창 객체
      const canvasDoc = this.editor.Canvas.getDocument();
      const canvasWin = canvasDoc.defaultView || canvasDoc.parentWindow;

      // 요소의 상대 위치 계산 (iframe 내부 기준)
      let rect = rteEl.getBoundingClientRect();

      // iframe 내부에서의 스크롤 위치 고려
      const scrollTop = canvasWin.scrollY || canvasDoc.documentElement.scrollTop;
      const scrollLeft = canvasWin.scrollX || canvasDoc.documentElement.scrollLeft;

      // iframe의 위치와 내부 요소 위치를 합쳐 최종 위치 계산
      // 중요: absolute 포지셔닝에서는 스크롤 위치도 고려해야 함
      const top = frameRect.top + rect.bottom + scrollTop;
      const left = frameRect.left + rect.left + scrollLeft;

      // 팝업을 캔버스 컨테이너에 추가하고 absolute 위치 사용
      const canvasContainer = frameEl.parentNode;
      if (canvasContainer) {
        // 팝업 요소를 캔버스 컨테이너에 추가
        if (popupEl.parentNode !== canvasContainer) {
          canvasContainer.appendChild(popupEl);
        }

        // 캔버스 컨테이너 내에서 상대적 위치 설정
        popupEl.style.position = 'absolute';

        // 캔버스 컨테이너 좌표로 변환
        const containerRect = canvasContainer.getBoundingClientRect();
        const relativeTop = top - containerRect.top;
        const relativeLeft = left - containerRect.left;

        popupEl.style.top = `${relativeTop}px`;
        popupEl.style.left = `${relativeLeft}px`;
      } else {
        // 캔버스 컨테이너를 찾을 수 없는 경우 기존 방식 사용
        document.body.appendChild(popupEl);
        popupEl.style.position = 'absolute';
        popupEl.style.top = `${top}px`;
        popupEl.style.left = `${left}px`;
      }

      // 화면 경계 체크 및 조정
      this.adjustPopupPosition(popupEl);
    },
    /**
     * 팝업이 화면 경계를 벗어나지 않도록 위치를 조정
     * @param {HTMLElement} popupEl - 조정할 팝업 요소
     */
    adjustPopupPosition(popupEl) {
      if (!popupEl) return;

      // 팝업이 캔버스 컨테이너 내에 있으면 컨테이너 기준으로 조정
      const container = popupEl.parentNode;
      if (container && container !== document.body) {
        const containerRect = container.getBoundingClientRect();
        const popupRect = popupEl.getBoundingClientRect();

        // 컨테이너 너비를 벗어나는지 확인
        if (popupRect.right > containerRect.right) {
          const newLeft = parseInt(popupEl.style.left) - (popupRect.right - containerRect.right) - 10;
          popupEl.style.left = `${Math.max(0, newLeft)}px`;
        }

        // 컨테이너 아래를 벗어나는지 확인
        if (popupRect.bottom > containerRect.bottom) {
          const newTop = parseInt(popupEl.style.top) - (popupRect.bottom - containerRect.bottom) - 10;
          popupEl.style.top = `${Math.max(0, newTop)}px`;
        }
      } else {
        // 기존 화면 경계 검사 코드
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const rect = popupEl.getBoundingClientRect();

        if (rect.right > viewportWidth) {
          const newLeft = parseInt(popupEl.style.left) - (rect.right - viewportWidth) - 10;
          popupEl.style.left = `${Math.max(0, newLeft)}px`;
        }

        if (rect.bottom > viewportHeight) {
          const newTop = parseInt(popupEl.style.top) - (rect.bottom - viewportHeight) - 10;
          popupEl.style.top = `${Math.max(0, newTop)}px`;
        }
      }
    },
    /**
 * RTE(Rich Text Editor) 툴바 설정
 */
    setupRichTextEditor() {
      const rte = this.editor.RichTextEditor;
      rte.remove('bold')
      rte.remove('italic')
      rte.remove('underline')
      rte.remove("strikethrough")
      rte.remove("link")

      // 1. 글꼴 (Font Family) - 선택 영역 처리 추가
      rte.add('fontFamily', {
        icon: '<span style="font-family: serif;">Font</span>',
        attributes: { title: '글꼴' },
        result: rte => {
          console.log("Font Family button clicked");

          // 팝업 활성화 상태 설정
          this.isRtePopupActive = true;

          // 지역 변수에 저장
          const editor = this.editor;

          // 폰트 목록
          const fonts = [
            { name: '맑은 고딕', value: 'Malgun Gothic, sans-serif' },
            { name: '굴림', value: 'Gulim, sans-serif' },
            { name: '돋움', value: 'Dotum, sans-serif' },
            { name: '바탕', value: 'Batang, serif' },
            { name: 'Arial', value: 'Arial, sans-serif' },
            { name: 'Helvetica', value: 'Helvetica, sans-serif' },
            { name: 'Times New Roman', value: 'Times New Roman, serif' },
            { name: 'Georgia', value: 'Georgia, serif' },
            { name: 'Verdana', value: 'Verdana, sans-serif' }
          ];

          // 컨테이너 생성
          let container = document.createElement('div');
          container.className = 'rte-popup-container font-family-container';
          container.style.position = 'absolute';
          container.style.background = 'white';
          container.style.border = '1px solid #ddd';
          container.style.borderRadius = '4px';
          container.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
          container.style.zIndex = '100';
          container.style.padding = '10px';
          container.style.display = 'flex';
          container.style.alignItems = 'center';

          // 이벤트 전파 중단 설정
          container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          container.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 폰트 선택용 셀렉트 박스
          let fontSelect = document.createElement('select');
          fontSelect.style.padding = '5px';
          fontSelect.style.width = '200px';
          fontSelect.style.borderRadius = '4px';
          fontSelect.style.border = '1px solid #ddd';

          // 셀렉트 박스에도 이벤트 중단 설정
          fontSelect.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          fontSelect.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 첫 번째 옵션 (안내 텍스트)
          let defaultOption = document.createElement('option');
          defaultOption.value = '';
          defaultOption.textContent = '글꼴 선택...';
          defaultOption.disabled = true;
          defaultOption.selected = true;
          fontSelect.appendChild(defaultOption);

          // 폰트 옵션 추가
          fonts.forEach(font => {
            let option = document.createElement('option');
            option.value = font.value;
            option.textContent = font.name;
            option.style.fontFamily = font.value;
            fontSelect.appendChild(option);
          });

          // 클로저로 this 참조 저장
          const that = this;

          // 값이 변경될 때마다 스타일 적용
          fontSelect.addEventListener('change', function () {
            const fontValue = this.value;
            if (fontValue) {
              console.log("Font family selected:", fontValue);

              try {
                // applyCommandToSelectionOrAll 함수 사용하여 선택 영역 처리
                applyCommandToSelectionOrAll(rte, 'fontName', fontValue);
              } catch (error) {
                console.error("Error applying font family:", error);

                // 오류 발생 시 대체 방법으로 시도
                try {
                  const selectedComponent = editor.getSelected();
                  if (selectedComponent) {
                    const currentStyles = { ...selectedComponent.getStyle() };
                    currentStyles['font-family'] = fontValue;
                    selectedComponent.setStyle(currentStyles);
                    selectedComponent.em.trigger('change:style');
                  }
                } catch (e) {
                  console.error("Fallback method also failed:", e);
                }
              }
            }
          });

          // 요소들을 컨테이너에 추가
          container.appendChild(fontSelect);

          // 먼저 DOM에 추가
          document.body.appendChild(container);

          // 위치 설정
          this.setRtePopupPosition(container, rte.el);

          // 간단한 커스텀 명령어 추가
          this.editor.Commands.add('custom-absolute-move', {
            run(editor) {
              const selected = editor.getSelected();
              if (selected) {
                console.log('custom-absolute-move: 컴포넌트가 선택되었습니다', selected.get('type'));
              } else {
                console.log('custom-absolute-move: 선택된 컴포넌트가 없습니다');
              }
            }
          });

          // 외부 클릭 이벤트 핸들러
          setTimeout(() => {
            const clickOutside = (e) => {
              const isRtePopup = e.target.closest('.rte-popup-container');
              if (!isRtePopup && !container.contains(e.target)) {
                container.remove();
                document.removeEventListener('click', clickOutside);
                that.isRtePopupActive = false;
              }
            };
            document.addEventListener('click', clickOutside);
          }, 10);
        }
      });

      // 2. 글자 크기 (Font Size) 부분 수정
      rte.add('fontSize', {
        icon: '<span style="font-size: 14px;">Size</span>',
        attributes: { title: '글자 크기' },
        result: rte => {
          // 팝업 활성화 상태 설정
          this.isRtePopupActive = true;

          // 컨테이너 생성
          let container = document.createElement('div');
          container.className = 'rte-popup-container font-size-input-container';
          container.style.position = 'absolute';
          container.style.background = 'white';
          container.style.border = '1px solid #ddd';
          container.style.borderRadius = '4px';
          container.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
          container.style.zIndex = '100';
          container.style.padding = '10px';
          container.style.display = 'flex';
          container.style.alignItems = 'center';

          // 이벤트 전파 중단 설정
          container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          container.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 사이즈 입력 필드
          let sizeInput = document.createElement('input');
          sizeInput.type = 'number';
          sizeInput.min = '8';
          sizeInput.max = '72';
          sizeInput.value = '14';
          sizeInput.style.width = '50px';
          sizeInput.style.marginRight = '5px';
          sizeInput.style.padding = '5px';

          // 이벤트 전파 중단 설정
          sizeInput.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          sizeInput.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 단위 선택 (px, pt, em 등)
          let unitSelect = document.createElement('select');
          unitSelect.style.padding = '5px';
          unitSelect.style.marginRight = '5px';

          // 셀렉트 박스에도 이벤트 중단 설정
          unitSelect.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          unitSelect.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          const units = ['px', 'pt', 'em', 'rem', '%'];
          units.forEach(unit => {
            let option = document.createElement('option');
            option.value = unit;
            option.textContent = unit;
            unitSelect.appendChild(option);
          });

          // 값 변경 시 즉시 스타일 적용하는 함수 (수정된 부분)
          const applyFontSize = () => {
            const size = `${sizeInput.value}${unitSelect.value}`;
            console.log("Applying font size:", size);

            try {
              // 현재 선택 영역 가져오기
              const selection = rte.selection();

              // 선택 영역이 있는지 확인
              if (selection && !selection.isCollapsed) {
                console.log("Applying font size to selection");

                // 선택된 텍스트 가져오기
                const selectedText = selection.toString();

                // 선택된 텍스트에 스타일 적용할 span 생성
                const html = `<span style="font-size: ${size};">${selectedText}</span>`;

                // 선택 영역에 HTML 삽입 (선택 영역 대체)
                rte.insertHTML(html);
              } else {
                // 선택 영역이 없으면 전체 컴포넌트에 적용 (개선된 방식)
                console.log("No selection, applying to whole component");

                // 현재 선택된 컴포넌트 직접 접근
                const selectedComponent = this.editor.getSelected();
                if (selectedComponent) {
                  console.log("Selected component:", selectedComponent);

                  // 1. 컴포넌트의 모든 내용에 접근
                  const el = selectedComponent.getEl();
                  if (el) {
                    // 2. 외부 컨테이너에 font-size 스타일 적용
                    const currentStyles = { ...selectedComponent.getStyle() };
                    currentStyles['font-size'] = size;
                    selectedComponent.setStyle(currentStyles);

                    // 3. 내부의 모든 span 태그에 font-size 적용 (중요: 이미 있는 span 태그의 스타일 처리)
                    try {
                      // RTE 요소 내부의 모든 span 태그 찾기
                      const spans = rte.el.querySelectorAll('span');
                      if (spans.length > 0) {
                        spans.forEach(span => {
                          // 기존 스타일 유지하면서 font-size만 업데이트
                          const currentStyle = span.getAttribute('style') || '';
                          const newStyle = currentStyle
                            .replace(/font-size\s*:\s*[^;]+;?/gi, '') // 기존 font-size 제거
                            + `font-size: ${size};`; // 새 font-size 추가

                          span.setAttribute('style', newStyle);
                        });

                        // 변경 알림
                        selectedComponent.em.trigger('change:content');
                      }
                    } catch (err) {
                      console.warn('Error updating spans:', err);
                    }

                    // 4. 변경 알림
                    selectedComponent.em.trigger('change:style');
                  }
                } else {
                  console.warn("No component selected");
                }
              }
            } catch (error) {
              console.error("Error applying font size:", error);
            }
          };

          // 값이 변경될 때마다 스타일 적용
          sizeInput.addEventListener('input', applyFontSize);
          unitSelect.addEventListener('change', applyFontSize);

          // 요소들을 컨테이너에 추가
          container.appendChild(sizeInput);
          container.appendChild(unitSelect);

          // 먼저 DOM에 추가
          document.body.appendChild(container);

          // 위치 설정
          this.setRtePopupPosition(container, rte.el);

          // 포커스 설정
          sizeInput.focus();
          sizeInput.select();

          // 외부 클릭 이벤트 핸들러
          setTimeout(() => {
            const clickOutside = (e) => {
              const isRtePopup = e.target.closest('.rte-popup-container');
              if (!isRtePopup && !container.contains(e.target)) {
                container.remove();
                document.removeEventListener('click', clickOutside);
                this.isRtePopupActive = false;
              }
            };
            document.addEventListener('click', clickOutside);
          }, 10);
        }
      });

      function applyCommandToSelectionOrAll(rte, command, value = null) {
        // 디버깅용 로그 추가
        // console.log(`Applying command: ${command}, value: ${value}`);

        try {
          const selection = rte.selection();
          const isCollapsed = selection && selection.isCollapsed;

          if (isCollapsed && rte.el && rte.doc) {
            // 선택 영역이 없을 때 전체 내용 선택
            const range = rte.doc.createRange();
            range.selectNodeContents(rte.el);

            const docSelection = rte.doc.getSelection();
            docSelection.removeAllRanges();
            docSelection.addRange(range);

            // 명령 실행
            // console.log("Applying to entire content");

            // 일반 명령 (bold, italic 등)의 경우
            if (command !== 'fontSize' && command !== 'fontName') {
              rte.exec(command, value);
            } else {
              // fontSize와 fontName의 경우 스타일 직접 적용
              switch (command) {
                case 'fontSize': {
                  // 중괄호로 새 블록 스코프 생성
                  const currentHTML = rte.el.innerHTML;
                  rte.el.innerHTML = `<span style="font-size: ${value};">${currentHTML}</span>`;
                  break;
                }
                case 'fontName': {
                  // 중괄호로 새 블록 스코프 생성
                  const content = rte.el.innerHTML;
                  rte.el.innerHTML = `<span style="font-family: ${value};">${content}</span>`;
                  break;
                }
                default:
                  rte.exec(command, value);
              }
            }

            // 선택 해제
            docSelection.removeAllRanges();

            // 변경 알림 - 수정된 부분
            // rte.em 대신 에디터의 이벤트 메커니즘 사용
            try {
              // 컴포넌트 모델 직접 접근
              const currentComponent = rte.currentComponent || rte.model;
              if (currentComponent && currentComponent.em) {
                currentComponent.em.trigger('change:component');
              }
            } catch (err) {
              console.warn('Error triggering change event:', err);
              // 오류가 발생해도 계속 진행 (함수는 실행되었으므로)
            }
          } else {
            // 선택 영역이 있을 때
            console.log("Applying to selection");

            if (command === 'fontSize') {
              // iframe 내에서 fontSize가 제대로 적용되지 않는 경우를 위한 대체 방법
              const selectedText = selection.toString();
              const html = `<span style="font-size: ${value};">${selectedText}</span>`;
              rte.insertHTML(html);
            } else if (command === 'fontName') {
              // 폰트 패밀리도 직접 HTML 삽입
              const selectedText = selection.toString();
              const html = `<span style="font-family: ${value};">${selectedText}</span>`;
              rte.insertHTML(html);
            } else {
              // 기본 exec 사용
              rte.exec(command, value);
            }
          }

          // 성공 로그
          // console.log(`Applied command ${command} successfully`);
        } catch (error) {
          console.error(`Error applying command ${command}:`, error);
        }
      }
      // 액션 정의에 적용
      rte.add('bold', {
        icon: '<b>B</b>',
        attributes: { title: '굵게' },
        result: rte => applyCommandToSelectionOrAll(rte, 'bold')
      });

      // 4. 기울임 (Italic)
      rte.add('italic', {
        icon: '<i>I</i>',
        attributes: { title: '기울임' },
        result: rte => applyCommandToSelectionOrAll(rte, 'italic')
      });

      // 5. 밑줄 (Underline)
      rte.add('underline', {
        icon: '<u>U</u>',
        attributes: { title: '밑줄' },
        result: rte => applyCommandToSelectionOrAll(rte, 'underline')
      });

      // 6. 취소선 (Strikethrough)
      rte.add('strikethrough', {
        icon: '<s>S</s>',
        attributes: { title: '취소선' },
        result: rte => applyCommandToSelectionOrAll(rte, 'strikeThrough')
      });

      // 7. 글자 색상 (Font Color) - 컬러 피커만 사용
      rte.add('textColor', {
        icon: '<span style="color: red;">A</span>',
        attributes: { title: '글자 색상' },
        result: rte => {
          // 팝업 활성화 상태 설정
          this.isRtePopupActive = true;

          // 컨테이너 생성
          let container = document.createElement('div');
          container.className = 'rte-popup-container color-picker-container';
          container.style.position = 'absolute';
          container.style.background = 'white';
          container.style.border = '1px solid #ddd';
          container.style.borderRadius = '4px';
          container.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
          container.style.zIndex = '100';
          container.style.padding = '15px';
          container.style.width = '220px';

          // 이벤트 전파 중단 설정
          container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          container.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 라벨 생성
          let label = document.createElement('div');
          label.textContent = '글자 색상';
          label.style.marginBottom = '10px';
          label.style.fontWeight = 'bold';

          // 컬러 피커 생성
          let colorPicker = document.createElement('input');
          colorPicker.type = 'color';
          colorPicker.value = '#000000'; // 기본 검정색
          colorPicker.style.width = '100%';
          colorPicker.style.height = '40px';
          colorPicker.style.padding = '0';
          colorPicker.style.border = '1px solid #ddd';
          colorPicker.style.borderRadius = '4px';
          colorPicker.style.marginBottom = '10px';

          // 이벤트 전파 중단 설정
          colorPicker.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          colorPicker.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 클로저로 this 참조 저장
          const that = this;

          // 색상 적용 함수 - applyCommandToSelectionOrAll 사용
          const applyColor = (color) => {
            console.log("Applying text color:", color);
            try {
              // applyCommandToSelectionOrAll 함수 사용
              applyCommandToSelectionOrAll(rte, 'foreColor', color);
            } catch (error) {
              console.error("Error applying text color:", error);
            }
          };

          // 색상 값 변경 이벤트
          colorPicker.addEventListener('input', function () {
            applyColor(this.value);
          });

          // 요소들을 컨테이너에 추가
          container.appendChild(label);
          container.appendChild(colorPicker);

          // 먼저 DOM에 추가
          document.body.appendChild(container);

          // 위치 설정
          this.setRtePopupPosition(container, rte.el);

          // 외부 클릭 이벤트 핸들러
          setTimeout(() => {
            const clickOutside = (e) => {
              const isRtePopup = e.target.closest('.rte-popup-container');
              if (!isRtePopup && !container.contains(e.target)) {
                container.remove();
                document.removeEventListener('click', clickOutside);
                that.isRtePopupActive = false;
              }
            };
            document.addEventListener('click', clickOutside);
          }, 10);
        }
      });

      // 8. 배경 색상 (Background Color) - 컬러 피커 및 투명도만 사용
      rte.add('hiliteColor', {
        icon: '<span style="background-color: yellow; padding: 0 3px;">A</span>',
        attributes: { title: '배경 색상' },
        result: rte => {
          // 팝업 활성화 상태 설정
          this.isRtePopupActive = true;

          // 컨테이너 생성
          let container = document.createElement('div');
          container.className = 'rte-popup-container color-picker-container';
          container.style.position = 'absolute';
          container.style.background = 'white';
          container.style.border = '1px solid #ddd';
          container.style.borderRadius = '4px';
          container.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
          container.style.zIndex = '100';
          container.style.padding = '15px';
          container.style.width = '220px';

          // 이벤트 전파 중단 설정
          container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          container.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 라벨 생성
          let label = document.createElement('div');
          label.textContent = '배경 색상';
          label.style.marginBottom = '10px';
          label.style.fontWeight = 'bold';

          // 컬러 피커 생성
          let colorPicker = document.createElement('input');
          colorPicker.type = 'color';
          colorPicker.value = '#FFFF00'; // 기본 노란색
          colorPicker.style.width = '100%';
          colorPicker.style.height = '40px';
          colorPicker.style.padding = '0';
          colorPicker.style.border = '1px solid #ddd';
          colorPicker.style.borderRadius = '4px';
          colorPicker.style.marginBottom = '10px';

          // 이벤트 전파 중단 설정
          colorPicker.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          colorPicker.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 투명도 슬라이더 컨테이너
          let alphaContainer = document.createElement('div');
          alphaContainer.style.marginBottom = '10px';

          // 투명도 라벨
          let alphaLabel = document.createElement('div');
          alphaLabel.textContent = '투명도: 100%';
          alphaLabel.style.marginBottom = '5px';

          // 투명도 슬라이더
          let alphaSlider = document.createElement('input');
          alphaSlider.type = 'range';
          alphaSlider.min = '0';
          alphaSlider.max = '100';
          alphaSlider.step = '1';
          alphaSlider.value = '100';
          alphaSlider.style.width = '100%';

          // 이벤트 전파 중단 설정
          alphaSlider.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          alphaSlider.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 클로저로 this 참조 저장
          const that = this;

          // 색상 및 투명도 적용 함수
          const applyBgColorWithAlpha = (hexColor, alpha) => {
            try {
              console.log("Applying background color with alpha:", hexColor, alpha);

              // hex를 rgb로 변환
              const r = parseInt(hexColor.slice(1, 3), 16);
              const g = parseInt(hexColor.slice(3, 5), 16);
              const b = parseInt(hexColor.slice(5, 7), 16);

              // rgba 색상 생성
              const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;
              const finalColor = alpha < 1 ? rgbaColor : hexColor;

              // applyCommandToSelectionOrAll 함수 사용
              applyCommandToSelectionOrAll(rte, 'hiliteColor', finalColor);
            } catch (error) {
              console.error("Error applying background color with alpha:", error);
            }
          };

          // 투명도 슬라이더 값 변경 시 라벨 업데이트 및 색상 적용
          alphaSlider.addEventListener('input', () => {
            const alphaValue = parseInt(alphaSlider.value);
            alphaLabel.textContent = `투명도: ${alphaValue}%`;

            // 색상과 투명도 함께 적용
            const hexColor = colorPicker.value;
            applyBgColorWithAlpha(hexColor, alphaValue / 100);
          });

          // 투명도 컨테이너에 요소 추가
          alphaContainer.appendChild(alphaLabel);
          alphaContainer.appendChild(alphaSlider);

          // 색상 값 변경 이벤트
          colorPicker.addEventListener('input', function () {
            // 현재 투명도 값 가져오기
            const alpha = parseInt(alphaSlider.value) / 100;

            // 색상과 투명도 함께 적용
            applyBgColorWithAlpha(this.value, alpha);
          });

          // 요소들을 컨테이너에 추가
          container.appendChild(label);
          container.appendChild(colorPicker);
          container.appendChild(alphaContainer);

          // 먼저 DOM에 추가
          document.body.appendChild(container);

          // 위치 설정
          this.setRtePopupPosition(container, rte.el);

          // 외부 클릭 이벤트 핸들러
          setTimeout(() => {
            const clickOutside = (e) => {
              const isRtePopup = e.target.closest('.rte-popup-container');
              if (!isRtePopup && !container.contains(e.target)) {
                container.remove();
                document.removeEventListener('click', clickOutside);
                that.isRtePopupActive = false;
              }
            };
            document.addEventListener('click', clickOutside);
          }, 10);
        }
      });

      // 9. 왼쪽 정렬 (Left Align) - PPT 스타일 아이콘
      rte.add('alignLeft', {
        icon: '<svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z"/></svg>',
        attributes: { title: '왼쪽 정렬' },
        result: rte => applyCommandToSelectionOrAll(rte, 'justifyLeft')
      });

      // 10. 가운데 정렬 (Center Align) - PPT 스타일 아이콘
      rte.add('alignCenter', {
        icon: '<svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M3,3H21V5H3V3M7,7H17V9H7V7M3,11H21V13H3V11M7,15H17V17H7V15M3,19H21V21H3V19Z"/></svg>',
        attributes: { title: '가운데 정렬' },
        result: rte => applyCommandToSelectionOrAll(rte, 'justifyCenter')
      });

      // 11. 오른쪽 정렬 (Right Align) - PPT 스타일 아이콘
      rte.add('alignRight', {
        icon: '<svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z"/></svg>',
        attributes: { title: '오른쪽 정렬' },
        result: rte => applyCommandToSelectionOrAll(rte, 'justifyRight')
      });

      // 3. 행간 (Line Height) - 수정된 버전
      rte.add('lineHeight', {
        icon: '<svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M10,13H22V11H10M10,19H22V17H10M10,7H22V5H10M6,7H8.5L5,3.5L1.5,7H4V17H1.5L5,20.5L8.5,17H6V7Z"/></svg>',
        attributes: { title: '행간' },
        result: rte => {
          // 팝업 활성화 상태 설정
          this.isRtePopupActive = true;

          // 지역 변수에 저장
          const editor = this.editor;

          // 슬라이더 UI 생성
          let container = document.createElement('div');
          container.className = 'rte-popup-container line-height-slider-container';
          container.style.position = 'absolute';
          container.style.background = 'white';
          container.style.border = '1px solid #ddd';
          container.style.borderRadius = '4px';
          container.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
          container.style.zIndex = '100';
          container.style.padding = '15px';
          container.style.width = '250px';

          // 이벤트 전파 중단 설정
          container.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          container.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          let label = document.createElement('div');
          label.textContent = '행간: 1.5';
          label.style.marginBottom = '10px';

          let slider = document.createElement('input');
          slider.type = 'range';
          slider.min = '1';
          slider.max = '3';
          slider.step = '0.1';
          slider.value = '1.5';
          slider.style.width = '100%';

          // 이벤트 전파 중단 설정
          slider.addEventListener('mousedown', (e) => {
            e.stopPropagation();
          }, true);

          slider.addEventListener('click', (e) => {
            e.stopPropagation();
          }, true);

          // 클로저로 this 참조 저장
          const that = this;

          // 행간 스타일 적용 함수
          const applyLineHeight = (value) => {
            console.log("Applying line height:", value);

            try {
              // 현재 선택된 컴포넌트 직접 접근
              const selectedComponent = editor.getSelected();
              if (selectedComponent) {
                console.log("Selected component:", selectedComponent);

                // 컴포넌트의 스타일 직접 설정
                const currentStyles = { ...selectedComponent.getStyle() };
                currentStyles['line-height'] = value;

                // 스타일 적용
                selectedComponent.setStyle(currentStyles);

                // 변경 알림
                selectedComponent.em.trigger('change:style');
                console.log("Line height applied successfully");
              } else {
                console.warn("No component selected");
              }
            } catch (error) {
              console.error("Error applying line height:", error);
            }
          };

          // 슬라이더 값이 변경될 때마다 행간 적용
          slider.oninput = () => {
            // 레이블 업데이트
            label.textContent = `행간: ${slider.value}`;

            // 행간 즉시 적용
            applyLineHeight(slider.value);
          };

          // 요소 추가
          container.appendChild(label);
          container.appendChild(slider);

          // 먼저 DOM에 추가
          document.body.appendChild(container);

          // 위치 설정
          this.setRtePopupPosition(container, rte.el);

          // 외부 클릭 이벤트 핸들러
          setTimeout(() => {
            const clickOutside = (e) => {
              const isRtePopup = e.target.closest('.rte-popup-container');
              if (!isRtePopup && !container.contains(e.target)) {
                container.remove();
                document.removeEventListener('click', clickOutside);
                that.isRtePopupActive = false;
              }
            };
            document.addEventListener('click', clickOutside);
          }, 10);
        }
      });
    },

    // GrapesJS 초기화 이후, 텍스트 타입을 오버라이드하여 기본적으로 resizable 속성 부여
    setupDefaultResizableText() {
      // 기존 텍스트 타입 설정 가져오기
      const textType = this.editor.DomComponents.getType('text');
      const textModel = textType.model;

      // 텍스트 타입 재정의 - 기본적으로 resizable 적용
      this.editor.DomComponents.addType('text', {
        model: {
          ...textModel,
          defaults: {
            ...textModel.defaults,
            resizable: {
              tl: 1, tc: 1, tr: 1,
              cl: 1, cr: 1,
              bl: 1, bc: 1, br: 1,
              keyWidth: 'width',
              keyHeight: 'height',
            }
          }
        }
      });
    },
    convertToResizableText(textComponent) {
      const style = { ...textComponent.getStyle() };
      const attributes = { ...textComponent.getAttributes() };

      // 최소한의 기본 스타일 설정 (없을 경우)
      if (!style.width) style.width = '100%';
      if (!style.minHeight) style.minHeight = '40px';
      if (!style.display) style.display = 'block';
      if (!style.textAlign && !textComponent.parent().is('table')) {
        style.textAlign = 'center';
      }

      // border 속성 제거 (테두리 제거)
      if (style.border) {
        delete style.border;
      }

      // data-gjs-type 속성 추가
      attributes['data-gjs-type'] = 'resizable-text';

      // 컴포넌트 타입과 속성 변경
      textComponent.set('type', 'resizable-text');
      textComponent.set('attributes', attributes);
      textComponent.setStyle(style);

      // resizable 속성 설정
      textComponent.set('resizable', {
        tl: 1, tc: 1, tr: 1,
        cl: 1, cr: 1,
        bl: 1, bc: 1, br: 1,
        keyWidth: 'width',
        keyHeight: 'height',
      });
    },
    /**
     * 커스텀 툴바 설정
     */
    setupCustomComponentToolbar() {
      // 커스텀 툴바 설정은 유지하되, 
      // 기존 코드와 같이 툴바 아이콘 제거 로직은 load 이벤트에서 처리

      // 기본 툴바 아이템 정의 (필요한 것만 추가)
      const customToolbar = [
        {
          attributes: { class: 'fa fa-pencil' },
          command: 'edit',
          label: '편집'
        }
      ];

      // 텍스트 컴포넌트용 툴바
      this.editor.DomComponents.addType('text', {
        model: {
          defaults: {
            toolbar: customToolbar
          }
        }
      });

      // 이미지 타입에 툴바 설정 (이미지 편집기 버튼 추가)
      this.editor.DomComponents.addType('image', {
        model: {
          defaults: {
            toolbar: [
              {
                attributes: { class: 'fa fa-pencil-square-o' },
                command: 'tui-image-editor',
                label: '이미지 편집'
              }
            ]
          }
        }
      });
    },
    /**
     * (부모/이동/복제/삭제) 툴바 아이콘 제거
     */
    removeDefaultToolbarIcons(cmp) {
      const oldToolbar = cmp.get('toolbar') || [];
      const removeCmds = ['core:component-exit', 'tlb-move', 'tlb-clone', 'tlb-delete'];

      const newToolbar = oldToolbar.filter(btn => {
        if (typeof btn.command === 'string') {
          return !removeCmds.includes(btn.command);
        }
        if (typeof btn.command === 'function') {
          const cls = btn.attributes?.class || '';
          if (cls.includes('fa-arrow-up')) {
            return false;
          }
        }
        return true;
      });

      if (cmp.get('draggable')) {
        newToolbar.push({
          attributes: {
            class: 'free-position-btn gjs-toolbar-item', // gjs-toolbar-item 추가
            title: '자유 위치 모드'
          },
          command: (editor) => {
            const selected = editor.getSelected();
            if (!selected) return;

            // 현재 스타일 저장
            const originalStyle = { ...selected.getStyle() };
            // const isPositioned = originalStyle.position === 'absolute' || originalStyle.position === 'fixed';

            // if (isPositioned) {
            //   // fixed에서 normal로 변경
            //   const newStyle = { ...originalStyle };
            //   delete newStyle.position;
            //   delete newStyle.top;
            //   delete newStyle.left;
            //   delete newStyle.zIndex;

            //   selected.setStyle(newStyle);
            //   console.log('일반 위치 모드로 변환');
            // } else {
              // normal에서 absolute로 변경
              const el = selected.getEl();
              if (!el) return;

              // 엘리먼트의 현재 위치 가져오기
              const rect = el.getBoundingClientRect();

              // 부모 요소 기준으로 상대적 위치를 계산
              let parent = el.offsetParent || el.parentElement;
              const parentRect = parent.getBoundingClientRect();

              // 에디터 프레임 보정 값
              let offsetX = 0;
              let offsetY = 0;

              // GrapesJS 에디터 프레임 내부인 경우 추가 보정
              const frame = editor.Canvas.getFrameEl();
              if (frame) {
                const frameRect = frame.getBoundingClientRect();
                offsetX = frameRect.left;
                offsetY = frameRect.top;
              }

              // 부모 기준 상대적 위치 계산 (스크롤 위치 고려)
              const relativeTop = rect.top - parentRect.top - offsetY + (parent.scrollTop || 0);
              const relativeLeft = rect.left - parentRect.left - offsetX + (parent.scrollLeft || 0);

              // absolute 위치 적용
              selected.addStyle({
                position: 'absolute',
                top: `${relativeTop + 200}px`,
                left: `${relativeLeft + 100}px`,
                zIndex: '9999'
              });
              console.log('자유 위치 모드로 변환 (relative: ', relativeTop, relativeLeft, ')');

              // 요소를 직접 드래그 가능하게 설정
              if (this.setupDraggable) {
                this.setupDraggable(editor, selected);
              }
            // }

            // 변경 내용 히스토리에 추가
            editor.UndoManager.add({
              object: selected,
              before: { style: originalStyle },
              after: { style: selected.getStyle() }
            });

            // UI 업데이트
            editor.refresh();
          },
          content: ''  // 내용은 비워두고 CSS로 처리
        });
      }

      cmp.set('toolbar', newToolbar);
    },

    // 새 메서드 추가: 직접 드래그 기능 구현
    setupDraggable(editor, component) {
      const el = component.getEl();
      if (!el) return;

      // 이미 드래그 이벤트가 설정되어 있는지 확인
      if (el.getAttribute('data-custom-draggable') === 'true') return;

      // 마우스 이벤트 핸들러
      let isDragging = false;
      let startX, startY, startTop, startLeft;

      const onMouseDown = (e) => {
        // 편집 모드나 다른 상호작용 중일 때는 무시
        if (component.get('status') === 'selected' && e.target.closest('.gjs-toolbar')) return;

        isDragging = true;

        // 시작 위치 저장
        startX = e.clientX;
        startY = e.clientY;

        const style = component.getStyle();
        startTop = parseInt(style.top) || 0;
        startLeft = parseInt(style.left) || 0;

        // 이벤트 리스너 추가
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        // 다른 이벤트 중단
        e.stopPropagation();
        e.preventDefault();
      };

      const onMouseMove = (e) => {
        if (!isDragging) return;

        // 이동 거리 계산
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        // 새 위치 적용
        component.addStyle({
          top: `${startTop + deltaY}px`,
          left: `${startLeft + deltaX}px`
        });

        // 업데이트
        editor.refresh();
      };

      const onMouseUp = () => {
        isDragging = false;

        // 이벤트 리스너 제거
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      // 요소에 이벤트 리스너 추가
      el.addEventListener('mousedown', onMouseDown);

      // 표시를 위한 속성 추가
      el.setAttribute('data-custom-draggable', 'true');

      // 드래그 시작 영역 표시를 위한 커서 스타일 추가
      component.addStyle({ cursor: 'move' });
    },
    addResizableTextType() {
      const dc = this.editor.DomComponents;

      dc.addType('resizable-text', {
        extend: 'text',
        isComponent: el => {
          if (el && el.dataset && el.dataset.gjsType === 'resizable-text') {
            return { type: 'resizable-text' };
          }
        },
        model: {
          defaults: {
            draggable: true,
            editable: true,
            style: {
              width: '100%',
              minHeight: '40px',
              padding: '10px',
              fontSize: '16px',
              // border 속성 제거 또는 수정
              // border: '1px solid transparent', // 투명 테두리로 변경하거나
              // border 속성 자체를 제거
              textAlign: 'center',
              display: 'block'
            },
            resizable: {
              tl: 1, tc: 1, tr: 1,
              cl: 1, cr: 1,
              bl: 1, bc: 1, br: 1,
              keyWidth: 'width',
              keyHeight: 'height',
            }
          }
        }
      });
    },
    /**
 * 컴포넌트에 드래그 기능을 활성화하는 메서드
 */
    enableDragging(component) {
      if (!component) return;

      // draggable 속성 설정
      component.set('draggable', true);

      // 필요한 경우 movable 속성도 설정
      const traits = component.get('traits') || [];
      const hasDraggableTrait = traits.some(trait => trait.type === 'checkbox' && trait.name === 'draggable');

      if (!hasDraggableTrait) {
        traits.push({
          type: 'checkbox',
          name: 'draggable',
          label: 'Draggable',
          changeProp: 1,
        });
        component.set('traits', traits);
      }

      // 요소 직접 설정
      const el = component.getEl();
      if (el) {
        el.setAttribute('draggable', 'true');
      }
    },
  },
  // ImageEditor.vue에서 beforeDestroy 훅 수정
  beforeDestroy() {
    // 에디터 인스턴스가 있는 경우에만 정리 작업 수행
    if (this.editor) {
      try {
        // 모든 DOM 조작 전에 element가 존재하는지 확인
        const editorContainer = document.querySelector('#gjs');
        if (editorContainer) {
          // 기존 에디터 정리 작업 수행
          this.editor.destroy();
          editorContainer.innerHTML = '';
        }
      } catch (error) {
        console.warn('에디터 정리 중 오류 발생:', error);
      }
    }

    // 기존에 등록된 이벤트 리스너 제거
    if (window.removeEventListener) {
      window.removeEventListener('resize', this.handleResize);
    }

    clearTimeout(this.saveTimeout);
    clearInterval(this.autoSaveInterval);
    clearInterval(this.rteCheckInterval);
  },
}
</script>

<style scoped>
.image-editor-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.editor-canvas {
  height: 100%;
  width: 100%;
}

::v-deep .gjs-editor {
  /* 에디터 기본 UI 요소 숨김 */
  border: none;
}

::v-deep .gjs-cv-canvas {
  /* 캔버스 영역 확장 */
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

/* GrapesJS 스타일 오버라이드 */
::v-deep .gjs-selected {
  outline: 2px solid #4b9fff !important;
}

::v-deep .gjs-toolbar {
  background-color: #4b9fff;
  border-radius: 3px;
}

::v-deep .gjs-toolbar-item {
  padding: 5px;
}

::v-deep .gjs-rte-toolbar {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* GrapesJS 스타일 오버라이드 - 선택된 요소에만 테두리 표시 */
::v-deep [data-gjs-type="resizable-text"] {
  border: 1px solid transparent !important;
}

::v-deep .gjs-selected[data-gjs-type="resizable-text"] {
  border: 2px solid #4b9fff !important;
}

::v-deep .gjs-badge {
  display: none !important;
}

/* RTE 툴바 디자인 개선 */
::v-deep .gjs-rte-toolbar {
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-wrap: wrap;
}

::v-deep .gjs-rte-actionbar {
  display: flex;
  flex-wrap: wrap;
}

::v-deep .gjs-rte-action {
  width: 32px;
  height: 32px;
  margin: 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: all 0.2s ease;
  color: #555;
  border: none;
  font-size: 14px;
}

::v-deep .gjs-rte-action:hover {
  background-color: #f5f5f5;
  color: #333;
}

::v-deep .gjs-rte-active {
  background-color: #e9f0ff;
  color: #1a73e8;
}

::v-deep .gjs-pn-commands {
  display: none;
}

::v-deep .gjs-pn-options {
  display: none;
}

::v-deep .gjs-pn-views {
  display: none;
}

/* 구분선 스타일 (선택 사항) */
::v-deep .gjs-rte-action-separator {
  width: 1px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 4px;
}

/* + 버튼 위치 및 스타일 */
::v-deep .add-block-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4b9fff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}

::v-deep .add-block-button span {
  font-size: 28px;
  line-height: 1;
  transform: translateY(-1px);
}

::v-deep .add-block-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

::v-deep .add-block-button.active {
  background-color: #7ec5ff;
  transform: rotate(45deg);
}

/* 패널 스타일 수정 */
::v-deep .gjs-pn-views-container {
  width: 300px;
  max-height: 0;
  /* 초기에는 높이 0으로 접힌 상태 */
  background-color: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;
  /* 넘치는 내용 숨김 */
  padding: 0;
  position: absolute;
  right: 20px;
  top: 80px;
  /* + 버튼 바로 아래 위치 */
  transition: max-height 0.3s ease;
  /* 높이 트랜지션 */
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.05);
  opacity: 0;
  /* 초기에는 투명 */
  visibility: hidden;
  /* 초기에는 보이지 않음 */
}

/* 패널 열림 상태 */
::v-deep .gjs-pn-views-container.panel-open {
  max-height: 410px;
  /* 열릴 때 최대 높이 설정 */
  opacity: 1;
  visibility: visible;
  transition: max-height 0.3s ease, opacity 0.2s ease, visibility 0s;
}

/* 기존 토글 버튼 제거 */
::v-deep .gjs-pn-views-container:before {
  display: none;
}

/* 기존 호버 효과 제거 */
::v-deep .gjs-pn-views-container:hover {
  right: 20px;
}

::v-deep .gjs-pn-views-container.panel-open:hover {
  top: 80px;
  /* 열린 상태에서는 위치 유지 */
}

/* 블록 카테고리 헤더 개선 */
::v-deep .gjs-block-category:first-child::before {
  content: "블록 추가";
  display: block;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  background-color: #f5f7f9;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #eee;
}

/* 블록 컨테이너 스타일 */
::v-deep .gjs-blocks-cs {
  border: none;
  height: 100%;
  background-color: #ffffff;
  padding: 0;
  /* 패딩 제거 */
}

/* 카테고리 스타일 */
::v-deep .gjs-block-category {
  border-bottom: 1px solid #f0f0f0;
  margin: 0;
  padding: 0;
}

/* Basic 카테고리 제목 숨기기 */
::v-deep .gjs-block-category:first-child .gjs-title {
  display: none;
}

/* 카테고리 제목 스타일 */
::v-deep .gjs-block-category .gjs-title {
  padding: 15px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s ease;
}

/* 블록 컨테이너 스타일 */
::v-deep .gjs-blocks-c {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  /* 명시적 2열 그리드 */
  gap: 10px;
  /* 간격 조정 */
  padding: 12px;
  background-color: #fafafa;
}

/* 첫 번째 카테고리(Basic)의 블록 컨테이너 패딩 조정 */
::v-deep .gjs-block-category:first-child .gjs-blocks-c {
  padding: 10px 5px;
}

/* 각 블록 아이템 스타일 */
::v-deep .gjs-block {
  width: 100% !important;
  min-height: 80px;
  padding: 8px;
  margin: 0;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* 블록 아이템 호버 효과 */
::v-deep .gjs-block:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #4b9fff;
}

::v-deep .gjs-block:hover svg path,
::v-deep .gjs-block:hover i {
  fill: #4b9fff;
  color: #4b9fff;
}

/* 블록 아이콘 스타일 */
::v-deep .gjs-block i,
::v-deep .gjs-block svg {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  color: #b8b8b8;
  fill: #b8b8b8;
}


/* 블록 라벨 스타일 */
::v-deep .gjs-block-label {
  font-size: 12px;
  font-weight: 500;
  color: #555555;
  text-align: center;
  padding: 4px 0;
  line-height: 1.2;
}

/* 블록 SVG 패스 스타일 */
::v-deep .gjs-block-svg-path {
  fill: #4b9fff;
}

/* 사이즈 표 블록 스타일 기본 블록과 동일하게 맞춤 */
::v-deep [data-id="size-table"] svg {
  margin-top: 10px;
  width: 50px;
  height: 40px;
  margin-bottom: 0px;
}

::v-deep [data-id="size-table"] svg path {
  fill: rgb(185, 165, 166);
}

/* Extra 카테고리 숨기기 */
::v-deep .gjs-block-category:nth-child(2) {
  display: none;
}

/* Forms 카테고리 숨기기 */
::v-deep .gjs-block-category:nth-child(3) {
  display: none;
}

/* 필요한 블록만 표시하기 - 텍스트, 이미지, 컬럼 블록들 + 사이즈 표 */
::v-deep .gjs-block-category:first-child .gjs-blocks-c>div:not(.gjs-fonts.gjs-f-text):not(.gjs-fonts.gjs-f-image):not(.gjs-fonts.gjs-f-b1):not(.gjs-fonts.gjs-f-b2):not(.gjs-fonts.gjs-f-b3):not(.size-table-block) {
  display: none;
}

/* 텍스트와 이미지는 상단에 배치하기 위한 순서 변경 */
::v-deep .gjs-fonts.gjs-f-text {
  order: 1;
}

::v-deep .gjs-fonts.gjs-f-image {
  order: 2;
}

/* 사이즈 표 블록 */
::v-deep [data-id="size-table"] {
  order: 7;
}

::v-deep .gjs-fonts.gjs-f-b1 {
  order: 4;
}

::v-deep .gjs-fonts.gjs-f-b2 {
  order: 5;
}

::v-deep .gjs-fonts.gjs-f-b3 {
  order: 6;
}

/* 블록 컨테이너에 flex 순서 적용 */
::v-deep .gjs-block-category:first-child .gjs-blocks-c {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 12px;
}

/* 열린 상태의 패널 스타일 */
::v-deep .gjs-block-category.gjs-open .gjs-blocks-c {
  display: flex;
}

/* 닫힌 상태의 패널 스타일 */
::v-deep .gjs-block-category:not(.gjs-open) .gjs-blocks-c {
  display: none;
}

/* 스타일 매니저 영역 스타일 */
::v-deep .gjs-sm-sectors {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

::v-deep .gjs-sm-sector {
  margin-bottom: 0;
  border-bottom: 1px solid #f0f0f0;
}

::v-deep .gjs-sm-sector-title {
  font-weight: 600;
  color: #333333;
  background-color: #ffffff;
  padding: 15px 20px;
}

::v-deep .gjs-sm-properties {
  padding: 10px;
}

::v-deep .gjs-sm-property {
  margin-bottom: 10px;
}

::v-deep .gjs-sm-label {
  font-size: 12px;
  color: #666666;
}

::v-deep .gjs-field {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #f8f9fa;
}

::v-deep .gjs-field:focus-within {
  border-color: #4b9fff;
}

/* 추가: 드래그 핸들러 호버 시 표시 */
::v-deep .gjs-badge {
  background-color: #4b9fff;
  border-radius: 2px;
}

/* 버튼 스타일 수정 (F 텍스트 제거) */
::v-deep .gjs-toolbar .free-position-btn.gjs-toolbar-item {
  position: relative !important;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 2px !important;
  border-radius: 2px !important;
  /* SVG 배경 이미지 추가 */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M12,2L8,6H11V10H8V7L4,11L8,15V12H11V16H8L12,20L16,16H13V12H16V15L20,11L16,7V10H13V6H16L12,2Z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 16px 16px !important;
}

/* 기존 가상 요소 제거 (대신 background-image를 사용) */
::v-deep .gjs-toolbar .free-position-btn.gjs-toolbar-item::after {
  content: none !important;
}

/* hideBlockManager가 true인 경우에만 적용되는 스타일 */
.hide-block-manager ::v-deep .add-block-button {
  display: none !important;
}

::v-deep .gjs-mdl-dialog #gjs-mdl-c {
  max-inline-size: 1200px !important;
  max-height: 1200px !important;
  /* ... */
}

::v-deep .tui-image-editor-container {
  /* max-width: 1000px !important;
  max-height: 1000px !important; */
  max-width: 100% !important;
  height: 1000px !important;
}
</style>